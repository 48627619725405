<template>
  <v-app>
    <div class="body-3 pt-6 pb-6 text-center" style="background-color:#e3f5ff; color:#315886; font-weight:600">
      <v-icon style="color:orange; font-weight:600">mdi-map-marker</v-icon> Rua José de Alencar, nº 121 
    </div>
    <div style="text-align:center;"> <br/>
      <img src="./../../assets/logo_horizontal.png" style="width:50%; max-width:280px" />
      </div>

    <dashboard-core-view />

    <dashboard-core-settings />

   
    <v-dialog  v-model="alert_msg"  width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>
        <v-card-text>
            {{msg_retorno}} Envio desabilitado ...
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  color="primary" text @click="alert_msg = false"
          >
            OK
          </v-btn>
        </v-card-actions>
 </v-card>

  </v-dialog>


    <v-container
      fluid
      tag="section">

      <base-material-card
        icon="mdi-comment-processing" color="light-blue darken-2"
        title="Sugestão de Parceiro"
        class="px-5 py-3">
  
        <v-card-text>
          <v-form ref="form" style="margin-top:20px"     lazy-validation> 

          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="mensagem.nome" style="font-size:20px; font-color:red;" 
                   Required :rules="[(v) => !!v || 'Campo Obrigatório', (v) => (v && v.length <= 30)|| 'Máximo de 30 caracteres']" 
                  clearable 
                  label="Nome *"
                  
                ></v-text-field>
              </v-col>
            
              <v-col cols="12">
                <v-text-field v-model="mensagem.telefone" style="font-size:20px; font-color:red;"  v-mask="'(##) #####-####'" 
                     Required :rules="[(v) => !!v || 'Campo Obrigatório', (v) => (v && v.length <= 30)|| 'Máximo de 30 caracteres']" 
                  clearable 
                  label="Celular *" 
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field v-model="mensagem.parceiro" style="font-size:20px; font-color:red;"  
                       small Required :rules="[(v) => !!v || 'Campo Obrigatório', (v) => (v && v.length <= 100)|| 'Máximo de 100 caracteres']" 
                  clearable 
                  label="Nome do Parceiro"
                ></v-text-field>
              </v-col>


              <small>*campos requeridos</small>
              <br/>  <br/> 

              </v-row>

              <v-row>
                <v-btn
                color="light-blue darken-2" class="white--text"
                @click="alert_msg = true" block      
              >

              <b>Enviar</b>
                    <v-icon right dark>   mdi-send</v-icon>          
              </v-btn>

              </v-row>

              
            
            
          </v-container>
           </v-form>

        </v-card-text>
      </base-material-card>

        <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
          &copy; 2021 Previparq.
        </div>
    </v-container>

   </v-app> 
  </template>
  <script>
  
    export default {
      name: 'LecuponSugestao',
  
      mounted(){
        
      },

      data () {
        return {
           alert_msg: '',
           email: '',
           msg_retorno: '',
           mensagem:{
            nome: '',
            telefone: '',
            parceiro: '',
            msg: '',

           }

          }
      },
  
     
      methods: {
       
        clear () {
            this.$refs.form.reset();
          },

          enviar(){
            alert()
          }
      },
    }
  </script>
  